<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">List All Courses</h2>
              <router-link :to="{ name: 'addCourse' }" class="btn btn-brand-ar-vr">Add New Course</router-link>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="status" label="Status" width="120">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span v-if="row.status" class="status-active text">Active</span>
                  <span v-else class="status-not-active text">Nonactive</span>
                </div>
              </el-table-column>
              <el-table-column min-width="300px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button @click.native="handleEdit($index, row)" type="edit-vr" size="sm">
                    <span>Edit</span>
                  </base-button>
                  <base-button
                    v-if="row.status"
                    class="status-active button"
                    @click.native="handleActive(row)"
                    size="sm"
                  >
                    <span>Nonactive</span>
                  </base-button>
                  <base-button
                    v-else
                    class="status-not-active button"
                    @click.native="handleActive(row)"
                    size="sm"
                  >
                    <span>Active</span>
                  </base-button>
                  <base-button @click.native="handleCourse($index, row)" type="detail-vr" size="sm">
                    <span>Detail Course</span>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="showModalActive" modal-classes="modal-secondary">
      <template slot="header">
        <h6 v-if="selectedCourse.status == 1" class="modal-title">Deactivate Course</h6>
        <h6 v-else class="modal-title">Activate Course</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span
          v-if="selectedCourse.status == 1"
          class="active-course"
        >deactivate this course</span>
        <span v-else class="nonactive-course">activate this course</span>
        ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showModalActive = false">Back</base-button>
          <base-button
            v-if="selectedCourse.status == 1"
            type="delete-vr"
            class="my-4"
            @click="handleSubmitActive"
          >deactivate</base-button>
          <base-button v-else type="success-vr" class="my-4" @click="handleSubmitActive">activate</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listCoursesMixin from './Mixins/listCoursesMixin'
import { getAllCourse, activeOrNonActive } from '@/services/course'
import Fuse from "fuse.js";
export default {
  mixins: [listCoursesMixin],
  name: "ListCourses",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['updatedAt', 'name', 'category.name', 'trainer.name', 'participant', 'rating', 'status'],
      tableColumns: [
        {
          prop: 'updatedAt',
          label: 'Date Update',
          minWidth: 180,
          sortable: true
        },
        {
          prop: 'name',
          label: 'Title Course',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'category.name',
          label: 'Category',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'trainer.name',
          label: 'Trainer/Author',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'participant',
          label: 'Number Participant',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'rating',
          label: 'Rating',
          minWidth: 150,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "listCourses",
          alias: "List Courses"
        }
      ],
      showModalActive: false,
      selectedCourse: {
        id: 0
      }
    };
  },
  methods: {
    handleEdit(index, row) {
      this.$router.push({ name: 'editCourse', params: { id: row.id } })
    },
    handleCourse(index, row) {
      this.$router.push({ name: 'detailCourse', params: { id: row.id } })
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    handleActive(val) {
      this.showModalActive = true
      this.selectedCourse = val
    },
    async getCourses() {
      try {
        const { data } = await getAllCourse()
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async handleSubmitActive() {
      try {
        const payload = {
          courseId: this.selectedCourse.id
        }
        const { data } = await activeOrNonActive(payload)
        const indexVal = this.tableData.findIndex(({ id }) => id === this.selectedCourse.id)
        this.tableData[indexVal].status = !data.status
        this.selectedCourse = null
        this.selectedCourse = {
          id: 0
        }
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
        this.selectedCourse = null
        this.selectedCourse = {
          id: 0
        }
      } finally {
        this.showModalActive = false
      }
    },
  },
  mounted() {
    this.getCourses()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.status-active {
  &.text {
    color: $status-pass-ar-vr;
  }
  &.button {
    background-color: $status-failed-ar-vr;
    color: #fff;
    border: none;
    min-width: 80px;
    padding: 0;
  }
}
.status-not-active {
  &.text {
    color: $status-failed-ar-vr;
  }
  &.button {
    background-color: $status-pass-ar-vr;
    color: #fff;
    border: none;
    min-width: 80px;
    padding: 0;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .active-course {
    color: $alert-ar-vr;
  }
  .nonactive-course {
    color: $status-pass-ar-vr;
  }
}
</style>